const INFO = {
	main: {
		title: "Vertex AI Reiko Yamamoto",
		name: "Reiko Y",
		email: "reiko@cyberhedz.com",
		logo: "../logo.png",
	},

	socials: {
		twitter: "https://twitter.com/",
		github: "https://github.com/",
		linkedin: "https://linkedin.com/",
		instagram: "https://instagram.com/",
		stackoverflow: "https://stackoverflow.com/",
		facebook: "https://facebook.com/",
	},

	homepage: {
		title: "Full-stack web and mobile app developer, and amateur astronaut.",
		description:
			"I am a backend developer with expertise in Node.js. I have experience in building scalable, secure and reliable web applications using various frameworks and technologies. I enjoy solving complex problems and learning new skills. I am passionate about creating high-quality code that follows best practices and industry standards. I am always looking for new challenges and opportunities to grow as a developer.",
	},

	about: {
		title: "I’m Reiko Y I live in San Francisco, where I design the future.",
		description:
			"I've worked on a variety of projects over the years and I'm proud of the progress I've made. Many of these projects are open-source and available for others to explore and contribute to. If you're interested in any of the projects I've worked on, please feel free to check out the code and suggest any improvements or enhancements you might have in mind. Collaborating with others is a great way to learn and grow, and I'm always open to new ideas and feedback.",
	},

	articles: {
		title: "I'm passionate about pushing the boundaries of what's possible and inspiring the next generation of innovators.",
		description:
			"Chronological collection of my long-form thoughts on programming, leadership, product design, and more.",
	},

	projects: [
		{
			title: "Aries",
			description:
				"Mar 21 - Apr 19",
			logo: "../aries.png",
			linkText: "See more...",
			link: "#",
		},

		{
			title: "Taurus",
			description:
				"Apr 20 - May 20",
			logo: "../taurus.png",
			linkText: "See more...",
			link: "#",
		},

		{
			title: "Gemini",
			description:
				"May 21 - Jun 20",
			logo: "../gemini.png",
			linkText: "See more...",
			link: "#",
		},

		{
			title: "Cancer",
			description:
				"Jun 21 - Jul 22",
			logo: "../cancer.png",
			linkText: "See more...",
			link: "#",
		},

		{
			title: "Leo",
			description:
				"Jul 23 - Aug 22",
			logo: "../leo.png",
			linkText: "See more...",
			link: "#",
		},
		{
			title: "Virgo",
			description:
				"Aug 23 - Sep 22",
			logo: "../aries.png",
			linkText: "See more...",
			link: "#",
		},
		{
			title: "Libra",
			description:
				"Sep 23 - Oct 22",
			logo: "../libra.png",
			linkText: "See more...",
			link: "#",
		},
		{
			title: "Scorpio",
			description:
				"Oct 23 - Nov 21",
			logo: "../scorpio.png",
			linkText: "See more...",
			link: "#",
		},
		{
			title: "Sagittarius",
			description:
				"Nov 22 - Dec 21",
			logo: "../sagittarius.png",
			linkText: "See more...",
			link: "#",
		},
		{
			title: "Capricorn",
			description:
				"Dec 22 - Jan 19",
			logo: "../capricorn.png",
			linkText: "See more...",
			link: "#",
		},
		{
			title: "Aquarius",
			description:
				"Jan 20 - Feb 18",
			logo: "../aries.png",
			linkText: "See more...",
			link: "#",
		},
		{
			title: "Pisces",
			description:
				"Feb 19 - Mar 20",
			logo: "../pisces.png",
			linkText: "See more...",
			link: "#",
		},
	
	],
};

export default INFO;
